import { useMemo, useState } from '_react';
import omit from 'lodash/omit';

import { type IndexedScope, PromptScopeType } from '../../../../../shared/foreground/ghostreader';

export function useFilteredPrompts(prompts: Map<PromptScopeType, IndexedScope>) {
  const [searchQuery, setSearchQuery] = useState('');
  const filteredPrompts = useMemo(() => {
    const internalPrompts = new Map<PromptScopeType, IndexedScope>();
    for (const [scopeType, scope] of prompts) {
      const scopePrompts = new Map();
      for (const [promptType, prompt] of scope.prompts) {
        if (prompt.isCustomizable !== undefined && !prompt.isCustomizable) {
          continue;
        }

        if (prompt.title.toLowerCase().includes(searchQuery)) {
          scopePrompts.set(promptType, prompt);
        }
      }
      if (scopePrompts.size > 0) {
        internalPrompts.set(scopeType, {
          ...omit(scope, 'prompts'),
          prompts: scopePrompts,
        });
      }
    }
    return internalPrompts;
  }, [searchQuery, prompts]);

  const search = (query: string) => setSearchQuery(query.toLocaleLowerCase());

  return { search, filteredPrompts };
}
