import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MangoQuery } from 'rxdb';

import { useFind } from '../../../../../shared/foreground/databaseHooks';
import {
  type Prompt,
  PromptScopeType,
  transformPrompts,
  useDefaultPrompts,
} from '../../../../../shared/foreground/ghostreader';
import { globalState, useIsStaffProfile } from '../../../../../shared/foreground/models';
import {
  migratePromptsFormatV1,
  resetPrompt,
  updatePrompt,
} from '../../../../../shared/foreground/stateUpdaters/clientStateUpdaters/readerSettings';
import { createCancelableToast, createToast } from '../../../../../shared/foreground/toasts.platform';
import getServerBaseUrl from '../../../../../shared/utils/getServerBaseUrl.platform';
import requestWithAuth from '../../../../../shared/utils/requestWithAuth.platformIncludingExtension';
import GhostreaderPromptsOptionsDropdown from '../../Dropdown/GhostreaderPromptsOptionsDropdown';
import SearchIcon from '../../icons/20StrokeSearch';
import { AccountSettingsPage } from '../AccountSettingsPage';
import { PromptDetails } from './components/PromptDetails';
import styles from './GhostreaderPromptsPage.module.css';
import { useFilteredPrompts } from './hooks';
import type { PromptDetailsHandle } from './types';
import { promptId } from './utils';

export const GhostreaderPromptsPage = () => {
  const latestArchivedDocumentDataQuery: MangoQuery = {
    selector: { triage_status: 'archive' },
    sort: [{ last_status_update: 'desc' }],
    limit: 1,
  };
  const [latestArchivedDocumentData] = useFind('documents', latestArchivedDocumentDataQuery);
  const [currentEditablePromptId, setCurrentEditablePromptId] = useState<string>();

  const overriddenPrompts = globalState(
    (state) =>
      state.persistent.settings.overriddenPrompts2 ?? state.persistent.settings.overriddenPrompts,
  );
  const defaultPrompts = useDefaultPrompts();
  const prompts = transformPrompts(defaultPrompts, overriddenPrompts);
  const isStaffProfile = useIsStaffProfile();

  const onPromptRender = async (prompt: Prompt) => {
    const controller = new AbortController();
    const toastId = createCancelableToast({
      content: 'Rendering prompt',
      onCancelClick: () => controller.abort(),
    });

    try {
      const payload = {
        template: {
          documentId: latestArchivedDocumentData?.[0]?.id,
          prompt: prompt.template,
        },
      };
      const response = await requestWithAuth(`${getServerBaseUrl()}/reader/api/render_prompt/`, {
        body: JSON.stringify(payload),
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
      });
      if (response.ok) {
        if (navigator?.clipboard?.writeText) {
          const data = await response.json();
          await navigator.clipboard.writeText(data?.renderedPrompt);
          createToast({
            category: 'success',
            content: 'Copied prompt to clipboard',
          });
        }
      } else {
        createToast({
          category: 'warning',
          content: 'Failed to render prompt',
        });
      }
    } finally {
      toast.dismiss(toastId);
    }
  };
  const onReset = async (promptScopeType: PromptScopeType, prompt: Prompt) => {
    await resetPrompt(promptScopeType, prompt.type);
    const promptDetails = promptRefs.current.get(promptId(promptScopeType, prompt));
    if (promptDetails) {
      promptDetails.reset();
    }
  };
  const onUpdate = async (scope: PromptScopeType, prompt: Prompt) => {
    setCurrentEditablePromptId(undefined);
    // migrate existing prompts (once) if necessary
    await migratePromptsFormatV1(defaultPrompts);
    await updatePrompt(scope, prompt);
  };
  const promptRefs = useRef<Map<string, PromptDetailsHandle | null>>(new Map());

  const onResetAllPrompts = () => {
    for (const [, promptRef] of promptRefs.current) {
      promptRef?.reset();
    }
  };

  const onCancelPromptEditing = () => {
    setCurrentEditablePromptId(undefined);
  };

  // filter prompts when searching
  const { filteredPrompts, search } = useFilteredPrompts(prompts);

  return (
    <AccountSettingsPage
      title="Ghostreader prompts"
      additionalHeaderContent={
        <div className={styles.searchWrapper}>
          <SearchIcon className={styles.searchIcon} />
          <input
            aria-labelledby="search-label"
            type="search"
            autoComplete="off"
            placeholder="Find prompts..."
            onChange={(e) => search(e.target.value)}
          />
        </div>
      }
    >
      <div className={styles.headerWrapper}>
        <h1 className={styles.mainHeader}>
          <Link to="/preferences">Preferences /</Link> Ghostreader prompts
        </h1>
        <div className={styles.layoutDropdownWrapper}>
          <GhostreaderPromptsOptionsDropdown onResetAllPrompts={onResetAllPrompts} />
        </div>
      </div>

      <ul className={styles.ghostreaderPromptsList}>
        {Array.from(filteredPrompts.values(), (scope) => {
          if (!isStaffProfile && scope.type !== PromptScopeType.Automatic) {
            return;
          }

          if (scope.isCustomizable !== undefined && !scope.isCustomizable) {
            return;
          }

          let currentScope: typeof scope;
          return Array.from(scope.prompts.values(), (prompt) => {
            const result = [];
            if (currentScope !== scope) {
              currentScope = scope;
              result.push(
                <li className={styles.categoryHeader} key={`scope-${scope.type}`}>
                  <span>{scope.title}</span>
                  {scope.description && <small>{scope.description}</small>}
                </li>,
              );
            }

            result.push(
              <PromptDetails
                ref={(el) => {
                  promptRefs.current.set(promptId(scope.type, prompt), el);
                }}
                key={promptId(scope.type, prompt)}
                prompt={prompt}
                scope={scope}
                isEditable={promptId(scope.type, prompt) === currentEditablePromptId}
                onCancel={onCancelPromptEditing}
                onEdit={setCurrentEditablePromptId}
                onRender={onPromptRender}
                onReset={onReset}
                onUpdate={onUpdate}
              />,
            );

            return result;
          });
        })}
      </ul>
    </AccountSettingsPage>
  );
};
