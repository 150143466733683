import { useCallback } from '_react';
import { EditorView } from '@codemirror/view';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import CodeMirror, { EditorState } from '@uiw/react-codemirror';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { canUseOpenAIModel, Prompt, PromptModel } from '../../../../../../shared/foreground/ghostreader';
import { globalState } from '../../../../../../shared/foreground/models';
import { DisplayTheme } from '../../../../../../shared/types';
import { ShortcutId } from '../../../../../../shared/types/keyboardShortcuts';
import { useKeyboardShortcut } from '../../../../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../../../../utils/shortcuts';
import Button from '../../../Button';
import { Dialog } from '../../../Dialog';
import { Dropdown, DropdownOption, DropdownOptionType } from '../../../Dropdown/Dropdown';
import SelectIcon from '../../../icons/20StrokeSelect';
import Tooltip from '../../../Tooltip';
import { PromptDetailsHandle } from '../types';
import { getSelectedLabel, jinja2, jinja2completions, promptId, readwiseTheme } from '../utils';
import styles from './PromptDetails.module.css';
import { PromptDetailsProps } from './types';

export const PromptDetails = forwardRef<PromptDetailsHandle, PromptDetailsProps>(
  (
    { prompt, scope, isEditable, onCancel, onEdit, onRender, onReset, onUpdate }: PromptDetailsProps,
    ref,
  ) => {
    const savedPromptTitle = prompt.title;
    const savedPromptModel = PromptModel[prompt.model];
    const savedPromptTemplate = prompt.template;
    const [currentPromptModel, setCurrentPromptModel] = useState(savedPromptModel);
    const [currentPromptTemplate, setCurrentPromptTemplate] = useState(savedPromptTemplate);
    const [currentPromptTitle, setCurrentPromptTitle] = useState(savedPromptTitle);
    const isDirty =
      currentPromptTitle !== savedPromptTitle ||
      currentPromptTemplate !== savedPromptTemplate ||
      currentPromptModel !== savedPromptModel;
    const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState(false);
    const [showResetPromptDialog, setShowResetPromptDialog] = useState(false);
    const isDefaultPrompt = prompt.isDefaultPrompt !== false;

    useEffect(() => {
      setCurrentPromptModel(savedPromptModel);
    }, [savedPromptModel]);
    useEffect(() => {
      setCurrentPromptTemplate(savedPromptTemplate);
    }, [savedPromptTemplate]);
    useEffect(() => {
      setCurrentPromptTitle(savedPromptTitle);
    }, [savedPromptTitle]);

    const modelOptions: DropdownOption[] = [
      {
        checked: currentPromptModel === PromptModel.GPT_35_TURBO,
        name: getSelectedLabel(PromptModel.GPT_35_TURBO),
        description: 'Included with subscription',
        type: DropdownOptionType.Item,
        onSelect: () => setCurrentPromptModel(PromptModel.GPT_35_TURBO),
      },
      {
        checked: currentPromptModel === PromptModel.GPT_4,
        isDisabled: !canUseOpenAIModel(PromptModel.GPT_4),
        name: getSelectedLabel(PromptModel.GPT_4),
        description: 'Uses your own OpenAI API key',
        type: DropdownOptionType.Item,
        onSelect: () => {
          setShowConfirmExpensiveModelDialog(true);
          setPreliminaryModel(PromptModel.GPT_4);
        },
      },
      {
        checked: currentPromptModel === PromptModel.GPT_4_TURBO,
        isDisabled: !canUseOpenAIModel(PromptModel.GPT_4_TURBO),
        name: getSelectedLabel(PromptModel.GPT_4_TURBO),
        description: 'Uses your own OpenAI API key',
        type: DropdownOptionType.Item,
        onSelect: () => {
          setShowConfirmExpensiveModelDialog(true);
          setPreliminaryModel(PromptModel.GPT_4_TURBO);
        },
      },
      {
        checked: currentPromptModel === PromptModel.GPT_4o,
        isDisabled: !canUseOpenAIModel(PromptModel.GPT_4o),
        name: getSelectedLabel(PromptModel.GPT_4o),
        description: 'Uses your own OpenAI API key',
        type: DropdownOptionType.Item,
        onSelect: () => {
          setShowConfirmExpensiveModelDialog(true);
          setPreliminaryModel(PromptModel.GPT_4o);
        },
      },
    ];
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showConfirmExpensiveModelDialog, setShowConfirmExpensiveModelDialog] = useState(false);
    const [preliminaryModel, setPreliminaryModel] = useState<PromptModel>();

    const resetAndCancel = () => {
      if (isDirty) {
        setShowDiscardChangesDialog(true);
      } else {
        onCancel();
      }
    };

    const shortcutsMap = useShortcutsMap();
    useKeyboardShortcut(shortcutsMap[ShortcutId.Esc], resetAndCancel);

    const reset = () => {
      setCurrentPromptTemplate(savedPromptTemplate);
      setCurrentPromptTitle(savedPromptTitle);
      setCurrentPromptModel(savedPromptModel);
    };

    useImperativeHandle(ref, () => ({ reset }));

    const isDarkMode = globalState(
      useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
    );

    const currentPrompt: Prompt = useMemo(
      () => ({
        ...prompt,
        model: currentPromptModel,
        template: currentPromptTemplate,
        title: currentPromptTitle,
      }),
      [currentPromptModel, currentPromptTemplate, currentPromptTitle, prompt],
    );

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li className={`${styles.ghostreaderPromptData}`}>
        <div className={styles.promptHeader}>
          {isEditable ? (
            <div className={styles.editableHeader}>
              <input
                autoComplete="off"
                value={currentPromptTitle}
                onChange={(event) => setCurrentPromptTitle(event.target.value)}
              />
              <Button
                disabled={isDefaultPrompt}
                variant="unstyled"
                onClick={() => setShowResetPromptDialog(true)}
              >
                Reset prompt
              </Button>
            </div>
          ) : (
            <>
              <div className={styles.name}>
                {prompt.title}
                {prompt.description && <small>{prompt.description}</small>}
              </div>
              <div className={styles.actions}>
                <Button
                  onClick={() => onEdit(promptId(scope.type, prompt))}
                  className={styles.visible}
                  variant="unstyled"
                >
                  Edit prompt
                </Button>
              </div>
            </>
          )}
        </div>
        {isEditable && (
          <div className={styles.promptEditor}>
            <CodeMirror
              basicSetup={{
                foldGutter: false,
                highlightActiveLine: false,
                highlightActiveLineGutter: false,
                lineNumbers: false,
                tabSize: 4,
              }}
              className={styles.promptInstruction}
              extensions={[
                jinja2,
                jinja2completions,
                EditorView.lineWrapping,
                EditorState.lineSeparator.of('\n'),
              ]}
              lang="jinja2"
              minHeight="192px"
              onChange={setCurrentPromptTemplate}
              placeholder="Enter your custom prompt"
              theme={readwiseTheme({}, isDarkMode)}
              value={currentPromptTemplate}
            />
            <div className={styles.actionsGroup}>
              <Dropdown
                appendToDocumentBody
                isOpen={isDropdownOpen}
                options={modelOptions}
                setIsOpen={setIsDropdownOpen}
                contentAlignment="start"
                contentClassName={styles.dropdownContent}
                trigger={
                  <DropdownMenu.Trigger asChild>
                    <div className={styles.dropdownTrigger}>
                      {`AI Model: ${getSelectedLabel(currentPromptModel)}`}
                      <SelectIcon />
                    </div>
                  </DropdownMenu.Trigger>
                }
              />
              <div className={styles.actions}>
                <Button variant="secondary" onClick={resetAndCancel}>
                  Cancel
                </Button>
                <Tooltip content="Copy rendered prompt to clipboard" placement="top">
                  <Button
                    disabled={!scope.canRender}
                    variant="secondary"
                    onClick={() => onRender(currentPrompt)}
                  >
                    Render
                  </Button>
                </Tooltip>
                <Button
                  variant="blue"
                  disabled={!isDirty}
                  onClick={() => onUpdate(scope.type, currentPrompt)}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
        {showConfirmExpensiveModelDialog && (
          <Dialog
            id="use-own-openai-api-key"
            title="Use your own OpenAI API key?"
            subtitle="Are you sure you want to switch to a more expensive model at your own expense?"
            actionTitle="Confirm"
            cancelTitle="Cancel"
            blueSecondaryActionButton
            action={() => {
              setShowConfirmExpensiveModelDialog(false);
              setCurrentPromptModel(preliminaryModel!);
              setPreliminaryModel(undefined);
            }}
            cancelAction={() => {
              setShowConfirmExpensiveModelDialog(false);
              setCurrentPromptModel(PromptModel.GPT_35_TURBO);
              setPreliminaryModel(undefined);
            }}
          />
        )}
        {showDiscardChangesDialog && (
          <Dialog
            id="discard-changes"
            title="Discard changes?"
            subtitle="Are you sure you want to discard your changes?"
            actionTitle="Discard"
            cancelTitle="Cancel"
            redActionButton
            action={() => {
              reset();
              onCancel();
              setShowDiscardChangesDialog(false);
            }}
            cancelAction={() => setShowDiscardChangesDialog(false)}
          />
        )}
        {showResetPromptDialog && (
          <Dialog
            id="reset-prompt"
            title="Reset prompt?"
            subtitle="Are you sure you want to reset to the default prompt?"
            actionTitle="Reset"
            cancelTitle="Cancel"
            redActionButton
            action={() => {
              onReset(scope.type, prompt);
              setShowResetPromptDialog(false);
            }}
            cancelAction={() => setShowResetPromptDialog(false)}
          />
        )}
      </li>
    );
  },
);
PromptDetails.displayName = 'PromptDetails';
